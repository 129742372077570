import React from 'react';

export default () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 16 11"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 10.5L16 1.86193L14.7387 0.5L8 7.77613L1.26133 0.499999L-5.95321e-08 1.86193L8 10.5Z" />
  </svg>
);
